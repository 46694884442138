<template>
  <b-container class="text-white">
    <div class="grid grid-cols-12">
      <div class="col-start-4 col-span-6 m-5 shadow">
        <h1 class="text-danger">Resource Not Found</h1>

        <h2 class="text-danger">please sign in</h2>
        <!-- <button 
          class="w-full mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"  
          :to="{ name: 'Login' }"
        >Login</button> -->
      </div>
    </div>
  </b-container>
</template>
